/** @flow
 * StratoDem Analytics : index
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import Layout from '../../../components/layout'

import SEO from '../../../components/SEO/seo'
import SDTeammate from '../../../components/SDTeammate/SDTeammate';

import ChristopherWardHeadshot from '../../../components/images/ChristopherWardHeadshot';

import './team.scss';

const About = () => (
  <Layout>
    <SEO
      title="Christopher Ward"
      keywords={['real estate research', 'stratodem', 'analytics', 'portfolio analysis', 'demographics']}
      description="StratoDem Analytics managing team - Christopher Ward"
    />
    <div style={{marginTop: 42}}>
      <SDTeammate
        name="Christopher Ward"
        title="Managing Director of Commercialization"
        email="chris@stratodem.com"
        linkedin="https://www.linkedin.com/in/christopher-ward-8198ab"
        education={{
          university: 'Syracuse University',
          degrees: ['BS Finance'],
        }}
        bio="is Managing Director of Commercialization for StratoDem Analytics. He leads marketing and sales initiatives and manages day-to-day operations. Chris brings over 20 years of experience in real estate and private equity, encompassing a wide range of functional responsibilities, including: product management, business development, marketing and investment research. Chris brings a deep appreciation and understanding of designing and delivering impactful technology solutions to the asset management community. His prior organizations included technology start-ups; innovation roles with eFront Financial, SunGard/Investran, Yardi Systems; and Fidelity Management and Research as an analyst within their real estate group. He has a BS in Finance from Syracuse University. Chris lives in Concord, MA with his wife and three children."
        headshotComponent={ChristopherWardHeadshot}
      />
    </div>
  </Layout>);

export default About;
